<template>
  <th-modal
    name="create-license"
    :title="$t('pages.staff.form.scopes.licenses:create.title')"
    width="650px"
    @close="close"
  >
    <el-steps :active="stepActive" finish-status="success">
      <el-step v-for="(step, index) in stepsAmount" :key="index" />
    </el-steps>

    <div v-loading="loading" class="mt-8">
      <div v-if="stepActive === 0" class="w-full">
        <div class="grid grid-cols-3 gap-6">
          <el-button
            v-for="t in types"
            :key="t.icon"
            class="m-0 h-auto block p-4 app-button"
            :disabled="t.disabled"
            @click="setTypeAndProceed(t)"
          >
            <img class="h-10 mb-3" :src="t.icon" />
            <p class="app-description">
              {{ t.description }}
            </p>
          </el-button>
        </div>
      </div>

      <div v-if="stepActive === 1">
        <p class="app-description">
          {{ $t('pages.licenses.create.billing.description') }}
        </p>

        <div class="flex justify-end w-full mt-4">
          <!-- Back -->
          <el-button @click="prev()">
            {{ $t('common.interactions.buttons.back') }}
          </el-button>

          <!-- Create -->
          <el-button
            v-permissions="{ scopes: ['resources:licenses:create'] }"
            type="primary"
            @click="onCreate()"
          >
            {{ $t('common.interactions.buttons.create') }}
          </el-button>
        </div>
      </div>

      <div v-if="stepActive === 2">
        <img class="h-20" :src="type.icon" />

        <p>
          {{ $t('pages.licenses.create.last_step.description') }}
        </p>

        <div class="flex justify-end w-full mt-4">
          <!-- Close -->
          <el-button @click="close">
            {{ $t('common.interactions.buttons.close') }}
          </el-button>
        </div>
      </div>
    </div>
  </th-modal>
</template>

<script>
import safeSet from 'just-safe-set'
import th from '@tillhub/javascript-sdk'

export default {
  data() {
    return {
      stepsAmount: 3,
      stepActive: 0,
      type: undefined,
      loading: false,
      linkOut: undefined,
      types: [
        {
          type: 'pos',
          icon:
            'https://storage.googleapis.com/tillhub-api-images/th-app-icons/icon_tillhub.svg',
          description: this.$t('pages.licenses.create.apps.pos.description'),
          disabled: true
        },
        {
          type: 'eda',
          icon:
            'https://storage.googleapis.com/tillhub-api-images/th-app-icons/icon_warehub.svg',
          description: this.$t('pages.licenses.create.apps.eda.description')
        },
        {
          type: 'cfd',
          icon:
            'https://storage.googleapis.com/tillhub-api-images/th-app-icons/icon_hubfront.svg',
          description: this.$t('pages.licenses.create.apps.cfd.description')
        }
        // {
        //   type: 'printer',
        //   icon:
        //     'https://storage.googleapis.com/tillhub-api-images/th-app-icons/icon_hubfront.svg',
        //   description: ''
        // }
      ]
    }
  },
  methods: {
    reset() {
      this.stepsAmount = 3
      this.stepActive = 0
      this.type = undefined
      this.loading = false
      this.linkOut = undefined
    },
    next() {
      this.stepActive = (this.stepActive + 1) % this.stepsAmount
    },
    prev() {
      this.stepActive = (this.stepActive - 1) % this.stepsAmount
    },
    async onCreate() {
      this.loading = true
      try {
        const { data } = await th.devices().create({ type: this.type.type })
        this.linkOut = data.id
        this.$emit('refresh')
        this.next()
      } catch (e) {
        const errorMessage = this.$t('pages.devices.create.post.error.message')
        this.$logException(e, {
          message: errorMessage
        })
      } finally {
        this.loading = false
      }
    },
    close() {
      this.reset()
      this.$thModal.hide('create-license')
    },
    setTypeAndProceed(type) {
      this.type = type
      this.next()
    }
  }
}
</script>

<style lang="css" scoped>
.app-button :deep(span) {
  display: block !important;
}

.app-description {
  white-space: pre-line;
  word-break: normal;
  text-align: initial;
  margin: 0;
  line-height: 1.2;
  font-size: 0.9em;
}
</style>
