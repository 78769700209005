<template>
  <th-page-wrapper>
    <!-- Create modal -->
    <create-form @refresh="refresh" />

    <!-- Bind modal -->
    <bind-cfd @refresh="refresh" />

    <!-- Table -->
    <th-datatable
      ref="table"
      :headers="headers"
      no-meta-check
      route-base="/resources/licenses"
      resource="devices"
      sortable
      multiple-select
      operations-width="300px"
      :show-operations="true"
      :resource-query="{ query: { deleted: false } }"
      :buttons="computedButtons"
      @selection-change="handleSelectionChange"
      @loading-error="handleLoadingError"
    >
      <!-- Actions -->
      <template #actions>
        <div>
          <actions
            v-permissions="{
              scopes: ['resources:licenses:delete']
            }"
            :selected-items="selectedItems"
            @delete-requested="handleDelete"
          />
        </div>
      </template>

      <!-- Operations -->
      <template #operations="scope">
        <div class="flex justify-end">
          <!-- Bind -->
          <el-button
            v-if="!scope.row.device_id && scope.row.type === 'cfd'"
            type="primary"
            size="small"
            plain
            @click="$thModal.show('bind-license')"
          >
            {{ $t('pages.licenses.edit.buttons.bind') }}
          </el-button>

          <!-- View device -->
          <router-link
            v-if="scope.row.device_id"
            :to="{
              name: 'resources-devices-edit',
              params: { id: scope.row.id }
            }"
          >
            <el-button type="primary" size="small" plain>
              {{ $t('pages.licenses.edit.buttons.view_device') }}
            </el-button>
          </router-link>

          <!-- Delete -->
          <el-button
            icon="Delete"
            size="small"
            class="ml-3 el-button--text-icon"
            @click.stop="askToDeleteLicense(scope.row)"
          />
        </div>
      </template>
    </th-datatable>
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import get from 'just-safe-get'
import flush from 'just-flush'
import Actions from '@components/actions/delete'
import CreateForm from './components/create-form'
import BindCfd from './components/bind/bind-cfd'
import { useMessagesStore } from '@/store/messages'

export default {
  name: 'LicensesAll',
  metaInfo() {
    return {
      title: this.$t('nav.main.items.resources.licenses')
    }
  },
  components: {
    Actions,
    CreateForm,
    BindCfd
  },
  data() {
    return {
      resources: {},
      selectedItems: []
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: 'Config/getCurrentLocation'
    }),
    computedButtons() {
      const canCreate = this.$checkPermissions({
        scopes: ['resources:licenses:create']
      })

      const buttons = []

      if (canCreate) {
        buttons.push({
          type: 'create',
          clickHandler: () => {
            // as this is a click handler, it's ok to manipulate data
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.$thModal.show('create-license')
          }
        })
      }

      return buttons
    },
    translations() {
      return {
        bound: this.$t('pages.devices.edit.form.bound.label'),
        cfd: this.$t('pages.devices.edit.form.cfd.label'),
        eda: this.$t('pages.devices.edit.form.eda.label'),
        free: this.$t('pages.devices.edit.form.free.label'),
        pending: this.$t('pages.devices.edit.form.pending.label'),
        printer: this.$t('pages.devices.edit.form.printer.label')
      }
    },
    headers() {
      return [
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => this.translations[row?.type] || '-'
        },
        {
          field: 'created_at',
          label: this.$t('pages.devices.all.headers.created_at'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            return this.$date.formatDateTimeWithTimezone(row.created_at)
          }
        },
        {
          field: 'location',
          label: this.$t('common.headers.location.title'),
          minWidth: 120,
          truncate: true,
          fallback: '-',
          formatter: (row) => {
            if (!row || !row.register) return '-'

            const register = this.resources.registers?.find(
              (item) => item.id === row.register
            )

            const branch = this.resources.branches?.find(
              (item) => item.id === register?.branch
            )

            if (branch || register) {
              const branchName = get(branch, 'name')
              const registerNumber = get(register, 'register_number')
              return flush([branchName, registerNumber]).join('–') || '-'
            }

            return '-'
          }
        },
        {
          field: 'is_available',
          label: this.$t('pages.devices.all.headers.is_available.title'),
          minWidth: 120,
          truncate: true,
          fallback: 'unknown',
          formatter: (row) => {
            if (row.device_id) {
              return this.$t('common.interactions.buttons.no')
            } else {
              return this.$t('common.interactions.buttons.yes')
            }
          }
        }
      ]
    }
  },
  watch: {
    currentLocation: 'fetchResources'
  },
  async beforeMount() {
    try {
      await this.fetchResources()
    } catch (err) {
      this.$logException(err, { trackError: false })
    }
  },
  methods: {
    async askToDeleteLicense(license) {
      const name = license.name || this.translations[license.type]
      const confirm = await this.$askToDelete(name)
      if (confirm) this.deleteLicense(license)
    },
    async deleteLicense(license) {
      try {
        const inst = th.devices()
        await inst.delete(license.id)
        this.refresh()
      } catch (err) {
        this.$notify.error({
          title: this.$t('common.forms.error.delete_fail.title'),
          message: this.$t('common.forms.error.delete_fail.message', {
            resource: this.$t('common.resource.license.singular')
          })
        })
        this.$logException(err, {
          message: this.$t('common.forms.error.delete_fail.message', {
            resource: this.$t('common.resource.license.singular')
          })
        })
      }
    },
    handleSelectionChange(val) {
      this.selectedItems = val
    },
    handleClose() {
      this.$router.push({ name: 'resources-licenses' })
    },
    refresh() {
      this.handleClose()
      this.$refs.table.refresh()
    },
    async handleDelete(payload) {
      const confirm = await this.$askToDeleteMany(
        payload,
        this.$t('common.delete_confirm.object_names.devices'),
        'name'
      )
      if (confirm) this.deleteResource(payload)
    },
    async deleteResource(payload) {
      if (this.selectedItems.length > 1) {
        this.handleBulkDelete(this.selectedItems)
        return
      }
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.license.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.license.singular')
      })

      try {
        const inst = th.devices()
        await inst.delete(payload[0].id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.refresh()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    },
    handleBulkDelete(items) {
      const inst = th.devices()

      const operations = items.map((item) => {
        return () => inst.delete(item.id)
      })

      const label = this.$t('common.error.action.delete.multiple', {
        resources: this.$t('common.resource.license.plural')
      })

      const currentRoute = this.$route.fullPath

      const fulfillment = () => {
        if (this.$route.fullPath === currentRoute) {
          this.refresh()
        }
      }

      useMessagesStore().startLocalOperation({
        operations,
        label,
        fulfillment
      })
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.devices.title')
        })
      })
    },
    async fetchResources() {
      const { registers, branchesV1 } = await this.$resourceFetch(
        'branchesV1',
        {
          resource: 'registers',
          query: { branch: this.currentLocation }
        }
      )
      this.resources = { branches: branchesV1, registers }
    }
  }
}
</script>

<style scoped></style>
