<template>
  <th-modal
    name="bind-license"
    :title="$t('pages.licenses.edit.buttons.bind')"
    width="650px"
    @close="close"
  >
    <el-steps :active="stepActive" finish-status="success">
      <el-step v-for="(step, index) in maxSteps" :key="index" />
    </el-steps>

    <div v-loading="loading" class="mt-8">
      <div v-if="stepActive === 0" class="w-full">
        <p class="mb-4">
          {{ $t('pages.devices.bind.register.description') }}
        </p>

        <!-- Register -->
        <th-input-title :title="$t('pages.audits.all.table.register')" />
        <remote-search-select
          v-model="cfd.register"
          resource="registers"
          :computed-fields="['register_number', 'name']"
          popper-append-to-body
          @resource-set="
            (v) => {
              handleFormInput(v.id, 'cfd.register')
              next()
            }
          "
        />
      </div>

      <div v-if="stepActive === 1">
        <p class="mb-6">
          {{ $t('pages.devices.bind.cfd_tokens.description') }}
        </p>

        <el-form ref="cfdForm" :model="cfd" :rules="rules">
          <!-- Short ID -->
          <el-form-item
            :label="$t('pages.devices.bind.cfd_tokens.short_id.placeholder')"
            prop="shortid"
          >
            <el-input
              v-model="cfd.shortid"
              class="w-full"
              :placeholder="
                $t('pages.devices.bind.cfd_tokens.short_id.placeholder')
              "
            />
          </el-form-item>

          <!-- Time Based Token -->
          <el-form-item
            :label="$t('pages.devices.bind.cfd_tokens.totp.placeholder')"
            prop="totptoken"
          >
            <el-input
              v-model="cfd.totptoken"
              class="w-full"
              :placeholder="
                $t('pages.devices.bind.cfd_tokens.totp.placeholder')
              "
            />
          </el-form-item>

          <div class="flex justify-end w-full mt-4">
            <!-- Submit -->
            <el-button type="primary" @click="handleCfdSubmit">
              {{ $t('common.interactions.buttons.submit') }}
            </el-button>
          </div>
        </el-form>
      </div>

      <div v-if="stepActive === 2">
        <p>
          {{ $t('pages.devices.bind.success.description') }}
        </p>

        <div class="flex justify-end w-full mt-4">
          <!-- Close -->
          <el-button plain @click="close">
            {{ $t('common.interactions.buttons.close') }}
          </el-button>

          <!-- Go to -->
          <el-button
            type="success"
            plain
            @click="$router.push(`/resources/devices/${linkOut}`)"
          >
            {{ $t('pages.devices.bind.success.goto') }}
          </el-button>
        </div>
      </div>
    </div>
  </th-modal>
</template>

<script>
import safeSet from 'just-safe-set'
import th from '@tillhub/javascript-sdk'
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  components: {
    RemoteSearchSelect
  },
  data() {
    return {
      maxSteps: 3,
      stepActive: 0,
      type: null,
      cfd: {
        register: null,
        shortid: null,
        totptoken: null
      },
      nextBlocked: true,
      linkOut: null,
      loading: false,
      typeOptions: [
        {
          value: 'cfd',
          label: this.$t('pages.devices.bind.type.types.cfd.label')
        },
        {
          value: 'printer',
          label: this.$t('pages.devices.bind.type.types.printer.label')
        }
      ],
      rules: {
        shortid: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ],
        totptoken: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    reset() {
      this.maxSteps = 3
      this.stepActive = 0
      this.type = null
      this.loading = false
      this.cfd = {
        register: null,
        shortid: null,
        totptoken: null
      }
      this.nextBlocked = true
      this.linkOut = null
    },
    next() {
      if (this.stepActive++ > 2) this.stepActive = 0
      this.nextBlocked = true
    },
    handleFormInput(v, prop) {
      safeSet(this.$data, prop, v)
    },
    handleCfdSubmit() {
      this.$refs.cfdForm.validate((valid) => {
        if (!valid) return false
        this.bindCfd()
      })
    },
    async bindCfd() {
      this.loading = true
      const payload = {
        client_account: this.$store.getters['Auth/getUser'],
        register: this.cfd.register,
        token: this.cfd.totptoken
      }

      try {
        const inst = th.devices()
        const { data } = await inst.bind(this.cfd.shortid, payload)
        this.linkOut = data.id
        this.$emit('refresh')
        this.next()
      } catch (err) {
        this.$logException(err, {
          message: this.$t('pages.devices.bind.post.error.message')
        })
      } finally {
        this.loading = false
      }
    },
    close() {
      this.reset()
      this.$thModal.hide('bind-license')
    }
  }
}
</script>
